import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { SEO } from "components";
import { PageHeading } from "components/layout";
import axios from "axios";
import useMenu from "state/useMenu";
/* Contact Page
============================================================================= */

const ContactPage = () => {
	// Config
	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [podcast, setPodcast] = useState(null);
	const [reason, setReason] = useState(null);
	const [message, setMessage] = useState("");
	const { setAd } = useMenu();

	useEffect(() => {
		setAd();
	}, []);
	// On Submit
	const onSubmit = async (e) => {
		e.preventDefault();
		const encode = (data) => {
			return Object.keys(data)
				.map(
					(key) =>
						encodeURIComponent(key) +
						"=" +
						encodeURIComponent(data[key])
				)
				.join("&");
		};
		await axios
			.post(
				"/",
				encode({
					email,
					name,
					podcast,
					reason,
					message,
					"form-name": "contact",
				}),
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				}
			)
			.then(() => {
				setEmail("");
				setPodcast(null);
				setReason(null);
				setMessage("");
				alert("Thank you for your message! We'll be in touch shortly.");
			});
	};

	// Render
	return (
		<>
			<SEO title="Contact Us" />
			<div className="w-full horizontal-padding padding-top">
				<div className="xl:w-4/6  md:w-5/6 w-full mx-auto">
					<form
						method="post"
						data-netlify="true"
						name="contact"
						hidden
						netlify
					>
						<input type="hidden" name="form-name" value="contact" />
						<input type="text" name="name" />
						<input type="email" name="email" />
						<textarea name="message" />
						<select name="podcast" />
						<select name="reason" />
					</form>
					<form className="padding-bottom" action="#" method="POST">
						<h2 className="font-display text-5xl pb-8">
							Contact Us
						</h2>
						<label className="font-body font-medium text-lg">
							Name
						</label>
						<input
							type="text"
							placeholder="Name"
							value={name}
							onChange={(e) => setName(e.target.value)}
							className="mt-2 w-full rounded-md py-3 px-4 font-body shadow-lg text-black mb-10 bg-gray-100 placeholder-black"
						/>
						<label className="font-body font-medium text-lg">
							Email Address
						</label>
						<input
							type="text"
							placeholder="Email Address"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							className="mt-2 w-full rounded-md py-3 px-4 font-body shadow-lg bg-white text-black mb-10 bg-gray-100 placeholder-black"
						/>
						<label className="font-body font-medium text-lg">
							Podcast
						</label>
						<select
							placeholder="Podcast"
							value={podcast}
							onChange={(e) => setPodcast(e.target.value)}
							className="mt-2 w-full rounded-md py-3 px-4 font-body shadow-lg bg-white text-black mb-10 bg-gray-100 placeholder-black"
						>
							<option>Select an option</option>
							<option value="Mont Icons">Mont Icons</option>
							<option value="Modern Australian Underground">
								Modern Australian Underground
							</option>
							<option value="Soju World">Soju World</option>
							<option value="Prahran Summer Jam">
								Prahran Summer Jam
							</option>
							<option value="Songs That Changed My Life">
								Songs That Changed My Life
							</option>
							<option value="The Space Between">
								The Space Between
							</option>
							<option value="Our Place">Our Place</option>
							<option value="Divergent">Divergent</option>
							<option value="Witness Box">Witness Box</option>
						</select>
						<label className="font-body font-medium text-lg">
							Reason for Enquiry
						</label>
						<select
							placeholder="Reason for Enquiry"
							value={reason}
							onChange={(e) => setReason(e.target.value)}
							className="mt-2 w-full rounded-md py-3 px-4 font-body shadow-lg bg-white text-black mb-10 bg-gray-100 placeholder-black"
						>
							<option>Select an option</option>
							<option value="Advertising">Advertising</option>
							<option value="Press">Press</option>
							<option value="Accessibility">Accessibility</option>
							<option value="Employment">Employment</option>
							<option value="General Enquiry">
								General Enquiry
							</option>
							<option value="Pitch Idea">Pitch Idea</option>
							<option value="Collaboration">Collaboration</option>
						</select>
						<label className="font-body font-medium text-lg">
							Message
						</label>
						<textarea
							placeholder="Message..."
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							className="mt-2 w-full rounded-md py-3 px-4 font-body shadow-lg bg-white text-black mb-10 h-56 bg-gray-100 placeholder-black"
						/>
						<button
							type="submit"
							onClick={onSubmit}
							className="rounded-md bg-black px-8 py-4 text-white font-display text-2xl shadow-lg w-full"
						>
							Submit
						</button>
					</form>
				</div>
			</div>
		</>
	);

	// Render
};

/* Export
============================================================================= */

export default ContactPage;
